import { createApp } from "vue";
import { createI18n } from "vue-i18n";
import vuetify from "./plugins/vuetify";
import { createGtm } from "vue-gtm";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import "@/assets/scss/main.scss";
import { registerGlobalComponent } from "./globalComponent";
import { GrowthBookVuePlugin } from "../src/plugins/growthbook";
import * as Sentry from "@sentry/vue";

const messages = {
  ja: require("./locales/ja.json"), // 日本語
  en: require("./locales/en.json"), // English
  kr: require("./locales/kr.json"), // 한국어
  np: require("./locales/np.json"), // नेपाली
  pr: require("./locales/pr.json"), // Português
  sc: require("./locales/sc.json"), // 简体中文
  tc: require("./locales/tc.json"), // 繁體中文
  tg: require("./locales/tg.json"), // Tagalog
  vi: require("./locales/vi.json"), // Tiếng Việt
  my: require("./locales/my.json"), // မြန်မာဘာသာစကား (burmese)
  ep: require("./locales/ep.json"), // Español(Spanish)
  id: require("./locales/id.json"), // Indonesian
};

const i18n = createI18n({
  locale: "ja", // set locale
  fallbackLocale: "en",
  warnHtmlInMessage: "off",
  messages, // set locale messages)
});

let app = createApp(App);
registerGlobalComponent(app);
app.use(router);
app.use(store);
app.use(vuetify);
app.use(i18n);
app.use(GrowthBookVuePlugin , {
  featuresEndpoint: process.env.VUE_APP_GROWTHBOOK_API_URL,
  enableDevMode: false,
});

if (process.env.NODE_ENV === "production") {
  app.use(
    createGtm({
      id: "GTM-PWG8LPQK",
      debug: false,
      vueRouter: router,
      trackOnNextTick: false,
    })
  );
}

if (process.env.NODE_ENV === "development") {
Sentry.init({
  app,
  dsn: "https://57d0661a056f2bb9133c32caa8307093@o4507938152448000.ingest.us.sentry.io/4507965113892864",

  beforeSend(event, hint) {
    const response = hint.originalException;

    if (response && response.status && (response.status === 400 || response.status === 401)) {
      return null;
    }
    if (response && response.code && (response.code === 400 || response.code === 401)) {
      return null;
    }

    return event;
  },

  // You can remove this option if you're not planning to use the Sentry Session Replay feature:
  integrations: [
    Sentry.browserTracingIntegration({ router }),
    Sentry.replayIntegration({
      maskAllText: false,
      blockAllMedia: false,
    }),
  ],

  // Capture 20% of the transactions
  tracesSampleRate: 0.3,

  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: [
    /^https:\/\/app-api\.dev\.visamane\.jp/
  ],
  replaysSessionSampleRate: 0.1, 
  replaysOnErrorSampleRate: 0.3,
  environment: process.env.NODE_ENV,
  });
};

if (process.env.NODE_ENV === "production") {
Sentry.init({
  app,
  dsn: "https://57d0661a056f2bb9133c32caa8307093@o4507938152448000.ingest.us.sentry.io/4507965113892864",

  beforeSend(event, hint) {
    const response = hint.originalException;

    if (response && response.status && (response.status === 400 || response.status === 401)) {
      return null;
    }
    if (response && response.code && (response.code === 400 || response.code === 401)) {
      return null;
    }

    return event;
  },

  // You can remove this option if you're not planning to use the Sentry Session Replay feature:
  integrations: [
    Sentry.browserTracingIntegration({ router }),
    Sentry.replayIntegration({
      maskAllText: true,
      blockAllMedia: true,
    }),
  ],

  // Capture 30% of the transactions
  tracesSampleRate: 0.3,

  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: [
    /^https:\/\/app-api\.visamane\.jp/
  ],
  replaysSessionSampleRate: 0.1, 
  replaysOnErrorSampleRate: 0.3,
  environment: process.env.NODE_ENV,
  });
};

app.mount("#app");
